import {
  type Clause, isEntityBlot, type MountedEditor, Query, RunCriteria, ScheduledBlot, useSystem
} from '@avvoka/editor'
import {BitArray, clone, removeFromArray, Source, toggleAttribute} from '@avvoka/shared'
import {getCurrentLocale} from "@stores/features/localization.store";
import {useLocalizationStore} from "@stores/features/localization.store";
import { getCurrentDocumentId, useDocumentStore } from '@stores/generic/document.store'
import { useParticipantStore } from '@stores/generic/participant.store'
import { useDocumentViewStore } from '@stores/views/documentView.store'
import axios from 'axios'
import { getActivePinia } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  _getDisallowedCharactersDelta, _parseWhitelist,
  _removeDisallowedCharactersDelta
} from "~/features/editor/character-validation";
import { DefaultTabs, defineTabs } from '../../features/editor/toolbar/toolbar'
import DocumentsApi from '~/api/DocumentsApi'
import { useErrorToast } from '@component-utils/toasts'

// biome-ignore lint/complexity/noStaticOnlyClass: This class is intentionally designed as a utility class.
export class NegoUtils {
  static async asyncSetLock() {
    return new Promise<boolean>((resolve) => {
      const editor = EditorFactory.get('draft').get()
      const lockState = editor.negotiation.lock.lockedToState
      if (lockState === 'cp' || lockState === 'signed') return resolve(false)
      if (lockState === 'me') return resolve(true)

      $_sec.ajax({
        url: `/documents/${editor.negotiation.id}/lock`,
        type: 'PATCH',
        dataType: 'text',
        data: { _method: 'POST' },
        success() {
          resolve(true)
        },
        error() {
          resolve(false)
        }
      })
    })
  }

  static isUnlocked(editor: MountedEditor) {
    return editor.negotiation.lock.lockedToState == 'none'
  }

  static isLockedToMe(editor: MountedEditor) {
    return editor.negotiation.lock.lockedToState == 'me'
  }

  static async unlock(editor: MountedEditor): Promise<boolean> {
    const success = await NegoUtils.asyncSetLock()
    if (!success) {
      if (!editor.negotiation.isConnected) {
        window.avv_dialog({
          alertMessage:
            "Unfortunately document couldn't been unlocked. Connection to server has been lost."
        })
        return false
      }
      if (
        editor.negotiation.lock.lockedToState !== 'none' &&
        editor.negotiation.lock.lockedToState !== 'me'
      ) {
        window.avv_dialog({
          alertMessage:
            "Unfortunately document couldn't been unlocked. Document was locked to other party in mean-time."
        })
        return false
      }
      window.avv_dialog({
        alertMessage: "Unfortunately document couldn't been unlocked."
      })
      return false
    }
    
    window.avv_dialog({
      snackMessage: 'Document was successfully unlocked.',
      snackStyle: 'notice'
    })

    const documentStore = useDocumentStore(getActivePinia())
    await documentStore.hydrateById(getCurrentDocumentId(), ['validations'], true)

    return true
  }

  static limitHeight() {
    const editor = EditorFactory.get('draft').get()
    const mountPoint = editor.scroll.node.parentElement?.parentElement
    const sidebar = mountPoint?.querySelector('.avv-sidebar') as HTMLElement
    if (sidebar)
      sidebar.style.height = `${
        window.innerHeight - sidebar.getBoundingClientRect().top
      }px`
    const container = mountPoint?.querySelector('.avv-container') as HTMLElement
    const containerBottomOffset = 10
    if (container)
      container.style.height = `${
        window.innerHeight -
        container.getBoundingClientRect().top -
        containerBottomOffset
      }px`
    const wrapper = mountPoint?.querySelector(
      '.avv-container-wrap'
    ) as HTMLElement
    if (wrapper)
      wrapper.style.height = `${
        window.innerHeight - wrapper.getBoundingClientRect().top
      }px`
  }

  static handleSpinner(display: HTMLElement['style']['display']) {
    const spinner = document.querySelector('.loader') as HTMLElement
    if (!spinner) return
    spinner.style.display = display
  }

  static async onDocumentLockChange(
    event: { lockedToState: string; lockedTo: string }
  ) {
    try {
      const response: { state: string } = await DocumentsApi.state({params: {id: AvvStore.state.document_id}})
      const status = response.state
      AvvStore.commit('SET_DOC_STATE', {
        status,
        party: event.lockedTo
      })
    } catch {
      useErrorToast(localizeText('document.notice.error_fetching_state'))
    }
  }

  static onReadyChange(
    event: { ready: boolean },
    events: { onReady: (data: unknown) => void },
    conditionData: unknown
  ) {
    if (event.ready) {
      events.onReady(conditionData)
    }
  }
}

export async function handleNegoEditor({
  socketUrl,
  document,
  onCreate,
  rtl
}: {
  socketUrl: string
  document: {
    document: number
    current_user: number
    users: Record<
      number,
      { name: string; party: string; role: string; approve: boolean }
    >
  }
  onCreate: (editor: MountedEditor) => void
  rtl: boolean
}) {
  const documentStore = useDocumentStore(getActivePinia())

  await Promise.all([
    documentStore.hydrateById(document.document, [
      'id',
      'user_id',
      'avv_roles',
      'docx_settings',
      'validations',
      'activity_log',
      'drafts',
      'settings_for_frontend'
    ]),
    useParticipantStore(getActivePinia()).hydrateById(document.document, [
      'id',
      'party_type',
      'user_id',
      'approve',
      'edit',
      'sign'
    ]),
    useDocumentViewStore(getActivePinia()).hydrateById(document.document),
  ])

  // Must be fetched after documentStore hydration above, therefore it's not in the array above
  const localizationStore = useLocalizationStore(getActivePinia());
  await localizationStore.hydrate({locale: await getCurrentLocale()}, ['localized_numbers'])

  const readOnly = AvvStore.state.active_participant?.edit !== 'All'
  const showOpenAI = AvvStore.state.openaiIntegration

  const toolbarIconsToRemove = []
  if (!showOpenAI) toolbarIconsToRemove.push('openAI')

  const toolbar = defineTabs(toolbarIconsToRemove, [], DefaultTabs)

  void EditorFactory.onCreate('draft').then(() => {
    const editor = EditorFactory.get('draft').get()

    /** events */
    editor.negotiation.onLockChange.subscribe((event) =>
      NegoUtils.onDocumentLockChange(event, document)
    )
    editor.negotiation.onFetchError.subscribe((event) =>
      window.avv_dialog({
        alertMessage:
          "We couldn't load your document. Please refresh page and contact support if problem persist."
      })
    )

    editor.onReady.subscribe(({ ready }) => {
      if (ready) NegoUtils.limitHeight()
    })
    window.addEventListener('resize', NegoUtils.limitHeight)

    /** Connect to nego server */
    editor.negotiation.connect(socketUrl, document.document, {
      receiveChanges: true,
      shareChanges: true,
      loadDocument: true,
      user: {
        id: document.current_user,
        party: document.users[document.current_user].party
      },
      users: document.users,
      async getToken() {
        const response = await axios.post<{ token: string }>('/user_tokens')
        if (response.data?.token) return response.data.token
        window.avv_dialog({
          alertMessage:
            "Unfortunately we couldn't obtain token for you. Please refresh page and contact support if problem persist."
        })
        throw new Error(
          `Unable to obtain token for user ${
            document.current_user
          }, please contact support. (response: ${JSON.stringify(
            response.data
          )}, status: ${response.status}))`
        )
      }
    })

    // The comment 'class' is a bit hack solution, therefore it needs special treatment
    // When a new line is added, we need to check if it has the comment's class and if it does
    // then remove the class
    const removeCommentSystem = () => {
      return useSystem([
        'comments:added-new-line',
        [ScheduledBlot] as const,
        RunCriteria.Custom((entity, type, data, frame, time) => {
          return (
            RunCriteria.Added().fn(entity, type, data, frame, time) &&
            isEntityBlot(entity, 'block') &&
            (entity.node as HTMLElement)?.hasAttribute('class')
          )
        }),
        ({ blots }) => {
          for (const line of blots) {
            const node = line.node as HTMLElement
            const classes = node?.getAttribute('class')?.split(' ') ?? []
            if (classes.length) {
              const classesToRemove = [
                'comment_highlight-first',
                'comment_highlight-last',
                'comment_highlight'
              ]

              for (const className of classesToRemove) {
                removeFromArray(classes, className)
              }

              if (classes.length) {
                node.setAttribute('class', classes.join(' '))
              } else {
                node.removeAttribute('class')
              }
            }
          }
        },
        ['updateOptimizeSystem'],
        [],
        'lastFrame'
      ])
    }
    removeCommentSystem.systemName = 'removeCommentSystem'

    editor.stages.addRuntimeSystem(removeCommentSystem)

    const editorReady = ref(false)
    editor.onReady.subscribe(({ ready }) => {
      editorReady.value = ready
    })

    const taggedClauses = computed(() => {
      return editor.query(Query<Clause>('clause'))
    })

    watch(
      [taggedClauses, AvvStore.state.lockedClauses],
      ([allTaggedClauses, lockedClauses]) => {
        for (const clause of allTaggedClauses) {
          const clauseId = clause.attributes['data-unique-id']
          const isLocked =
            lockedClauses[clauseId] &&
            lockedClauses[clauseId].locked_rule_ids.length > 0
          const isContenteditable = clause.node?.attributes.contenteditable?.value !== 'false'
          const shouldToggle = isLocked && isContenteditable

          if (shouldToggle) {
            toggleAttribute(clause.node as Element, 'contenteditable', 'false', true)
          }
        }
      },
      { immediate: true, deep: true }
    )

    watch(() => clone(documentStore.validations), (validations, oldValidations) => {
      if(validations.character_whitelist.enabled && validations.character_whitelist.enabled !== oldValidations.character_whitelist.enabled) {
        const updateDelta = _getDisallowedCharactersDelta(editor.scroll.getDelta().ops, _parseWhitelist(validations.character_whitelist.whitelist));
        editor.update(updateDelta, new BitArray().set(Source.API).set(Source.TRACKING_CHANGES));
      } else if(!validations.character_whitelist.enabled && validations.character_whitelist.enabled !== oldValidations.character_whitelist.enabled) {
        const updateDelta = _removeDisallowedCharactersDelta(editor.scroll.getDelta());
        editor.update(updateDelta, new BitArray().set(Source.API).set(Source.TRACKING_CHANGES));
      }
    }, {deep: true})

    onCreate(editor)

    return
  })

  const participantStore = useParticipantStore(getActivePinia())

  EditorFactory.create({
    id: 'draft',
    bounds: '#editor',
    mode: 'document',
    placeholder: 'Write here or import from docx...',
    rtl,
    readOnly,
    toolbar,
    loadSidebar: participantStore.hasAccessToEditor
  })
}
